import React from 'react'

import Layout from 'components/Layout'

export default ({ pageContext: { post } }) => (
  <Layout pageTitle={post.name}>
    <oma-grid-row>
      <section
        className='section'
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </oma-grid-row>
  </Layout>
)
